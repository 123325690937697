
import { strict as assert } from "assert";

import { Component, Vue } from "vue-property-decorator";
import apollo from "./plugins/apollo";
import eventBus, { BusEvent } from "./plugins/event-bus";
import config from "@shared/smartcharge-config";
import auth from "./plugins/auth0";

import "vue-datetime/dist/vue-datetime.css";

declare var COMMIT_HASH: string;

interface AlertMessage {
  show: boolean;
  message: string | undefined;
}
@Component({})
export default class App extends Vue {
  authorized!: boolean;
  help_url!: string;
  info!: AlertMessage;
  warning!: AlertMessage;
  error!: AlertMessage;
  data() {
    const data = {
      authorized: apollo.authorized,
      help_url: config.HELP_URL,
      info: {
        show: Boolean(config.GLOBAL_INFO_MESSAGE),
        message: config.GLOBAL_INFO_MESSAGE,
      },
      warning: {
        show: Boolean(config.GLOBAL_WARNING_MESSAGE),
        message: config.GLOBAL_WARNING_MESSAGE,
      },
      error: {
        show: Boolean(config.GLOBAL_ERROR_MESSAGE),
        message: config.GLOBAL_ERROR_MESSAGE,
      },
    };

    // Logic for global messages
    const dismissed = localStorage.getItem(`dismissed_info`);
    if (dismissed !== null) {
      if (data.info.show && data.info.message === dismissed) {
        data.info.show = false;
      } else {
        localStorage.removeItem(`dismissed_info`);
      }
    }
    return data;
  }
  closedInfo() {
    assert(this.info.message !== undefined);
    localStorage.setItem(`dismissed_info`, this.info.message);
  }

  async mounted() {
    eventBus.$on(BusEvent.AlertError, (message: string) => {
      this.error.message = message;
      this.error.show = true;
    });
    eventBus.$on(BusEvent.AlertWarning, (message: string) => {
      this.warning.message = message;
      this.warning.show = true;
    });
    eventBus.$on(BusEvent.AlertClear, () => {
      this.warning.show = false;
      this.error.show = false;
    });
    eventBus.$on(BusEvent.AuthenticationChange, () => {
      this.authorized = apollo.authorized;
    });
  }
  errorCaptured(err: Error, _vm: Vue, _info: string) {
    if (err.message && err.name) {
      this.error.message = `${err.name}: ${err.message}`;
    } else {
      this.error.message = (err as any).toString();
    }
    this.error.show = true;
    return false;
  }

  get version() {
    return typeof COMMIT_HASH === "string"
      ? `(#${COMMIT_HASH.substr(0, 6)})`
      : "";
  }

  appReload() {
    window.location.reload();
  }

  get singleUserMode() {
    return config.SINGLE_USER !== "false";
  }

  async login() {
    if (this.singleUserMode) {
      this.$router.push("/login");
    } else {
      auth.login();
    }
  }
  async logout() {
    await apollo.logout();
    eventBus.$emit(BusEvent.AuthenticationChange);
    if (this.singleUserMode) {
      this.$router.push("/about");
    } else {
      auth.logout();
    }
  }
}
