
import { Component, Vue, Prop } from "vue-property-decorator";
import { makePublicID } from "@shared/utils";

@Component({ components: {} })
export default class RemoveDialog extends Vue {
  @Prop({ type: String, required: true }) declare readonly label: string;
  @Prop({ type: String, required: true }) declare readonly id: string;

  dialog!: boolean;
  confirmCode!: string;
  data() {
    return {
      dialog: false,
      confirmCode: "",
    };
  }
  confirmCodeRules(value: string) {
    if (value && value.length > 0) {
      if (this.valid) return true;
      return `incorrect code`;
    }
    return `required`;
  }

  get publicID(): string {
    return makePublicID(this.id).toUpperCase();
  }
  get valid(): boolean {
    return this.confirmCode.toUpperCase() === this.publicID;
  }
  doCancel() {
    this.dialog = false;
    this.confirmCode = "";
  }
  doConfirm() {
    this.$emit("action", this.confirmCode);
    this.dialog = false;
  }
}
