
import { Component, Vue, Prop } from "vue-property-decorator";
import { GQLSchedule, GQLScheduleType, GQLVehicle } from "@shared/sc-schema";
import { DateTime } from "luxon";
import { DatetimePopup } from "vue-datetime";
import { relativeTime } from "@shared/utils";
import { getVehicleLocationSettings } from "@shared/sc-utils";
import apollo from "@app/plugins/apollo";

@Component({ components: { DatetimePopup } })
export default class ScheduleItem extends Vue {
  @Prop({ type: Object, required: true }) declare readonly schedule: GQLSchedule;
  @Prop({ type: Object, required: true }) declare readonly vehicle: GQLVehicle;
  @Prop({ type: Boolean, default: false }) declare readonly newSchedule: boolean;

  refreshKey!: number; // workaround to reset date picker step
  timeMenu!: boolean;
  levelMenu!: boolean;
  capturing!: boolean;
  levelSlider!: number;

  isSaving!: boolean;
  isRemoving!: boolean;

  data() {
    return {
      refreshKey: 0,
      timeMenu: false,
      levelMenu: false,
      capturing: false,
      levelSlider: this.schedule.level || this.vehicle.maximumLevel,

      isSaving: false,
      isRemoving: false,
    };
  }

  get nowLocal(): DateTime {
    return DateTime.local();
  }
  get scheduleDateTime(): DateTime {
    const dt =
      this.schedule.time ||
      new Date(
        Math.ceil(Date.now() / 60e4) * 60e4 + 12 * 60 * 60e3
      ).toISOString();
    return DateTime.fromISO(dt).toLocal();
  }
  stopCapture() {
    setTimeout(() => (this.capturing = false), 150);
  }
  cancelLevel() {
    this.levelSlider = this.schedule.level || this.vehicle.maximumLevel;
    this.levelMenu = false;
  }
  async setLevel() {
    this.schedule.level = this.levelSlider;
    this.levelMenu = false;
    await this.save();
  }
  get directLevel(): number {
    const settings = getVehicleLocationSettings(this.vehicle);
    return settings.directLevel;
  }
  async setDateTime(datetime: DateTime) {
    const was = this.schedule.time;
    if (datetime < DateTime.utc()) {
      this.schedule.time = new Date(
        Math.ceil((Date.now() + 5 * 60e3) / 60e4) * 60e4
      ).toISOString();
    } else {
      this.schedule.time = datetime.toJSDate().toISOString();
    }
    if (this.schedule.time !== was) {
      await this.save();
    }
  }

  async addSchedule() {
    this.isSaving = true;
    this.$emit(`add`, () => {
      this.isSaving = false;
    });
  }

  debounceTimer?: any;
  async save() {
    if (!this.schedule.id) return; // assert!?

    this.isSaving = true;
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(async () => {
      await apollo.updateSchedule(
        this.schedule.id,
        this.schedule.vehicleID,
        this.schedule.type,
        this.schedule.level,
        (this.schedule.time && new Date(this.schedule.time)) || null
      );
      this.isSaving = false;
    });
  }

  async removeSchedule() {
    this.isRemoving = true;
    await apollo.removeSchedule(this.schedule.id, this.schedule.vehicleID);
    this.isRemoving = false;
  }

  get scheduleIcon(): string {
    switch (this.schedule.type) {
      case GQLScheduleType.Trip:
        return "mdi-road-variant";
      case GQLScheduleType.Manual:
        return "mdi-ev-station";
      default:
        return "mdi-alarm";
    }
  }
  get timeIcon(): string {
    switch (this.schedule.type) {
      case GQLScheduleType.Trip:
        return "mdi-clock-start";
      default:
        return "mdi-clock-end";
    }
  }
  get schedulePrettyDate(): string {
    if (this.schedule.time) {
      const rt = relativeTime(new Date(this.schedule.time));
      return rt.date + " - " + rt.time;
      // return DateTime.fromISO(this.schedule.time).toFormat("yyyy-MM-dd HH:mm");
    } else {
      return "N/A";
    }
  }
}
