
import { Component, Vue } from "vue-property-decorator";
import { gql } from "apollo-boost";
import EditVehicle from "@app/components/edit-vehicle.vue";
import EditLocation from "@app/components/edit-location.vue";
import { GQLVehicle, GQLLocation } from "@shared/sc-schema";

@Component({
  components: { EditVehicle, EditLocation },
  apollo: {
    locations: {
      query: gql`
        query GetLocations {
          locations {
            id
            ownerID
            name
            geoLocation {
              latitude
              longitude
            }
            geoFenceRadius
            providerData
            priceList {
              id
              name
            }
          }
        }
      `,
    },
    vehicles: {
      query: gql`
        query GetVehicles {
          vehicles {
            id
            ownerID
            name
            maximumLevel
            locationSettings {
              locationID
              directLevel
              goal
            }
            providerData
          }
        }
      `,
      //update: data => data.vehicles,
      watchLoading(isLoading, _countModifier) {
        this.loading = isLoading;
      },
      pollInterval: 5000,
    },
  },
})
export default class Home extends Vue {
  loading?: boolean;
  vehicles?: GQLVehicle[];
  locations?: GQLLocation[];

  data() {
    return { loading: false, vehicles: undefined, locations: undefined };
  }
}
