
import { Component, Vue, Prop } from "vue-property-decorator";
import EditVehicleLocationSettings from "@app/components/edit-vehicle-location-settings.vue";
import RemoveDialog from "@app/components/remove-dialog.vue";
import deepmerge from "deepmerge";
import apollo from "@app/plugins/apollo";
import equal from "fast-deep-equal";
import {
  GQLVehicle,
  GQLLocation,
  GQLVehicleLocationSetting,
} from "@shared/sc-schema";
import { DefaultVehicleLocationSettings } from "@shared/sc-utils";
import { UpdateVehicleParams } from "@shared/sc-client";

@Component({
  components: { EditVehicleLocationSettings, RemoveDialog },
})
export default class EditVehicle extends Vue {
  @Prop({ type: Object, required: true }) declare readonly vehicle: GQLVehicle;
  @Prop({ type: Array, required: true }) declare readonly locations: GQLLocation[];

  saving!: { [key: string]: boolean };

  data() {
    return {
      saving: {
        name: false,
        maximumLevel: false,
        auto_hvac: false,
        auto_port: false,
        disabled: false,
      },
    };
  }
  async created() {}

  nameRules(value: string) {
    if (value && value.length > 0) {
      return true;
    }
    return `Required`;
  }

  maximumLevelRules(value: string) {
    const v = parseInt(value) || 0;
    if (v < 50 || v > 100) {
      return `allowed range 50% - 100%`;
    }
    return true;
  }

  locationSettings(): any[] {
    return (
      (this.locations &&
        this.locations
          .filter((l) => l.ownerID === this.vehicle.ownerID)
          .map((l) => {
            const settings: GQLVehicleLocationSetting =
              (this.vehicle.locationSettings &&
                this.vehicle.locationSettings.find(
                  (f) => f.locationID === l.id
                )) ||
              DefaultVehicleLocationSettings(l.id);
            return {
              name: l.name,
              settings,
            };
          })) ||
      []
    );
  }

  async doConfirm(code: string) {
    await apollo.removeVehicle(this.vehicle.id, code);
    this.$emit("refresh");
  }

  get name(): string {
    return this.vehicle.name;
  }
  set name(value: string) {
    this.vehicle.name = value;
    this.save("name");
  }
  get maximumLevel(): string {
    return this.vehicle.maximumLevel.toString();
  }
  set maximumLevel(value: string) {
    const v = parseInt(value);
    if (v) {
      this.vehicle.maximumLevel = v;
      this.save("maximumLevel");
    }
  }
  get auto_hvac(): boolean {
    return this.vehicle.providerData && this.vehicle.providerData.auto_hvac;
  }
  set auto_hvac(value: boolean) {
    this.vehicle.providerData.auto_hvac = value;
    this.save("auto_hvac");
  }
  get auto_port(): boolean {
    return this.vehicle.providerData && this.vehicle.providerData.auto_port;
  }
  set auto_port(value: boolean) {
    this.vehicle.providerData.auto_port = value;
    this.save("auto_port");
  }
  get disabled(): boolean {
    return this.vehicle.providerData && this.vehicle.providerData.disabled;
  }
  set disabled(value: boolean) {
    this.vehicle.providerData.disabled = value;
    this.save("disabled");
  }

  debounceTimer?: any;
  touchedFields: any = {};
  clearSaving: any = {};
  async save(field: string) {
    delete this.clearSaving[field];
    this.$set(this.saving, field, true);

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(async () => {
      const form: any = this.$refs.form;
      if (form.validate && form.validate()) {
        const update: UpdateVehicleParams = {
          id: this.vehicle.id,
          providerData: {},
        };
        if (this.saving["name"]) {
          update.name = this.vehicle.name;
        }
        if (this.saving["maximumLevel"]) {
          update.maximumLevel = this.vehicle.maximumLevel;
        }
        if (this.saving["auto_hvac"]) {
          update.providerData.auto_hvac = this.vehicle.providerData.auto_hvac;
        }
        if (this.saving["auto_port"]) {
          update.providerData.auto_port = this.vehicle.providerData.auto_port;
        }
        if (this.saving["disabled"]) {
          update.providerData.disabled = this.vehicle.providerData.disabled;
        }
        if (equal(update.providerData, {})) {
          delete update.providerData;
        }

        this.clearSaving = deepmerge(this.clearSaving, this.saving);

        await apollo.updateVehicle(update);

        for (let [key, value] of Object.entries(this.clearSaving)) {
          if (value) {
            this.$set(this.saving, key, false);
          }
        }
      }
    }, 800);
  }
}
